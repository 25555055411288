.AteUpload {
  & .ant-upload-list-picture-card {
    margin: 4px;

    & .ant-upload-list-item {
      padding: 0 !important;
      overflow: hidden;

      & .ant-upload-list-item-image {
        object-fit: cover !important;
      }
    }
  }
}
  
  
@primary-color: rgba(252, 145, 50, 1);@error-color: rgba(250, 0, 54, 1);@text-color: rgba(0, 0, 0, 0.8);@info-color: rgba(182, 17, 199, 1);@background-color: rgba(249, 248, 249, 1);@disabled-color: rgba(236, 235, 236, 1);