@import './theme/index.less';

.AteSidebarLayout {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  min-height: 100dvh;
}

.AteSidebarLayout > .ant-layout {
  min-height: 100vh;
  min-height: 100dvh;
}

.AteLayoutContent {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.AteLayoutContent > .AteFooter {
  margin-top: auto;
}

@primary-color: rgba(252, 145, 50, 1);@error-color: rgba(250, 0, 54, 1);@text-color: rgba(0, 0, 0, 0.8);@info-color: rgba(182, 17, 199, 1);@background-color: rgba(249, 248, 249, 1);@disabled-color: rgba(236, 235, 236, 1);