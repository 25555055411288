@import './theme/index.less';

.AteLayoutSider {
  height: 100vh;
  height: 100dvh;
  position: sticky !important;
  top: 0;
  z-index: 100;
}

.AteLayoutSider .AteLayoutSiderContent {
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.AteLayoutSider .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}


@media screen and (max-width: 768px) {
  .AteLayoutSider {
    position: fixed!important;
  }
}

.AteBackdrop {
  display: none;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.AteBackdrop.-open {
  opacity: 1;
  pointer-events: all;
}

@media screen and (max-width: 768px) {
  .AteBackdrop {
    display: block;
  }
}


.AteMenu {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.ant-menu-item-group-title {
  color: white !important;
}

.AteMenuItem {
  padding-left: 16px !important;
}

.AteMenuItem.-active {
  background: @primary-gradient;
}

.AteMenuItem.AteMenuItem.-logoutButton {
  margin-top: auto;
}

@primary-color: rgba(252, 145, 50, 1);@error-color: rgba(250, 0, 54, 1);@text-color: rgba(0, 0, 0, 0.8);@info-color: rgba(182, 17, 199, 1);@background-color: rgba(249, 248, 249, 1);@disabled-color: rgba(236, 235, 236, 1);