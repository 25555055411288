@import "../../UI/theme/index.less";

// Carte de position de distributeur

.AtePositionCard {
  border: 1px solid @border-color-base;
  &.-active {
    border: 1px solid;
    border-image-slice: 1;
    border-image-source: @primary-gradient
  }
}
.AtePositionCard * {
  border-radius: 0 !important;
}

.AtePositionTitleContainer {
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: space-between;
}
.AtePositionTitleText {
  overflow-x: hidden;
white-space: break-spaces;
}


.AteUniquePositionCard {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1000;

  >.AtePositionCard .ant-collapse-content {
    max-height: 45vh;
    overflow-y: scroll;
  }
}

.AteMultiplePositionLayout {
  display: flex;
  gap: 16px;
  height: 100%;
}

.AtePositionsList {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  gap: 16px;
}

.AteMapLayout {
  position: relative;
  height: 65vh;
}

.AteMapContainer {
  width: 100%;
}


@primary-color: rgba(252, 145, 50, 1);@error-color: rgba(250, 0, 54, 1);@text-color: rgba(0, 0, 0, 0.8);@info-color: rgba(182, 17, 199, 1);@background-color: rgba(249, 248, 249, 1);@disabled-color: rgba(236, 235, 236, 1);