@import '../UI/theme/index.less';

.AteAuthPage {
  background: black;
  display: flex;
  width: 100%;
  min-height: 100dvh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AteAuthPageLogo {
  width: 100%;
  max-width: 320px;
  margin-bottom: 1rem;
}

.AteAuthCard {
  max-width: 900px;
  margin: 12px !important;
}
.AteSignupLoading {
  width: 100%;
  display: flex;
  justify-content: center;
}

#firebaseui_container {
  & {
    width: auto;
    margin: 12px;
  }

  .firebaseui-container {
    border-radius: @border-radius-base;
  }

  .firebaseui-button {
    border-radius: @border-radius-base;
  }

  .mdl-button--colored {
    background: @primary-gradient;
  }

}


@primary-color: rgba(252, 145, 50, 1);@error-color: rgba(250, 0, 54, 1);@text-color: rgba(0, 0, 0, 0.8);@info-color: rgba(182, 17, 199, 1);@background-color: rgba(249, 248, 249, 1);@disabled-color: rgba(236, 235, 236, 1);