@import "../../UI/theme/index.less";

.AteTableBatchActionsWrapper {
  padding-top: 1rem;
  position: relative;
}

.AteTableBatchActions {
  position: absolute;
  animation: AteActionsSlideIn 400ms ease-out forwards;
  background-color: white;
  width: 100%;
  border-top-left-radius: @border-radius-base;
  border-top-right-radius: @border-radius-base;

}

@keyframes AteActionsSlideIn {
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(-100%);
  }

}
@primary-color: rgba(252, 145, 50, 1);@error-color: rgba(250, 0, 54, 1);@text-color: rgba(0, 0, 0, 0.8);@info-color: rgba(182, 17, 199, 1);@background-color: rgba(249, 248, 249, 1);@disabled-color: rgba(236, 235, 236, 1);